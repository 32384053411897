import React from 'react';
import PropTypes from 'prop-types';
import './twoButtons.scss';

const TwoButtons = ({ title1, title2, buttonLink1, buttonLink2 }) => {
  return (
    <section className="position-relative twoButtons">
      <div className="container pb-7">
        <div className="row">
          <div className="col col-12 d-flex flex-wrap justify-content-center">
            <section className="position-relative card-benefit px-10 py-10 py-xl-7 flex-grow-1 mb-5">
              <h3 className="card-title text-primary">
                {title1}
              </h3>
                <div className="cta-button">
                { buttonLink1 && <a href={buttonLink1.path} className="mt-4 btn-tg btn-tg-sale" target="_blank" rel="noopener noreferrer">{buttonLink1.label}</a> }
              </div>
            </section>
            <section className="position-relative card-benefit px-10 py-10 py-xl-7 flex-grow-1 mb-5">
              <h3 className="card-title text-primary">
                {title2}
              </h3>
              <div className="cta-button">
                { buttonLink2 && <a href={buttonLink2.path} className="mt-4 btn-tg btn-tg-sale" target="_blank" rel="noopener noreferrer">{buttonLink2.label}</a> }
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

TwoButtons.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  buttonLink1: PropTypes.object,
  buttonLink2: PropTypes.object
};

export default TwoButtons;
