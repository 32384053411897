import React from 'react';
import PropTypes from 'prop-types';
import './templateHeader.scss';
import MemoVideoPlayer from '../../Common/VideoPlayer/VideoPlayer';
import CtaButtonBlock from '../CtaButtonBlock';

const TemplateHeader = ({
  bgTitle,
  smTitle,
  buttonLink,
  videoURL,
  isCaptureEmailForm = false,
  openEmailFormCallback = () => {}
}) => {

  return (
    <section className="position-relative templateHeader">
      <div className="container pt-7">
        <div className="row justify-content-center">
          <div className="col col-12 text-center">
            <h1 className="h1 text-primary">
              {smTitle && <div>
                <span className="subtitle font-weight-bold text-primary">{smTitle}</span>
                <br />
              </div>}
              {bgTitle}
            </h1>
          </div>
          <div className="col col-12 mt-5">
            {videoURL && <MemoVideoPlayer
              video={{url: videoURL, platform: 'vimeo'}}
              autoplay={false}
              hasControl={true}
              loop={false}
              title={true}
              muted={false}
            />}
          </div>
          {
            buttonLink && (
              <CtaButtonBlock
                label={buttonLink.label}
                path={buttonLink.path}
                isCaptureEmailForm={isCaptureEmailForm}
                openEmailFormCallback={openEmailFormCallback}
                className={`mt-5`}
              />
            )
          }
        </div>
      </div>
    </section>
  );
};

TemplateHeader.propTypes = {
  bgTitle: PropTypes.string,
  smTitle: PropTypes.string,
  buttonLink: PropTypes.object,
  videoURL: PropTypes.string,
  isCaptureEmailForm: PropTypes.bool,
  openEmailFormCallback: PropTypes.func
};

export default TemplateHeader;
