import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout/Layout';
import TemplateHeader from "../../components/TemplatePages/TemplateHeader/TemplateHeader";
import TitleList from "../../components/TemplatePages/TitleList/TitleList";
import ImageBlock from "../../components/TemplatePages/ImageBlock/ImageBlock";
import Instructions from "../../components/TemplatePages/Instructions/Instructions";
import TwoButtons from "../../components/TemplatePages/twoButtons/TwoButtons";

export const OldTargetCustomersTemplate = ({ frontmatter }) => {
  return (
    <React.Fragment>
      <TemplateHeader
        bgTitle={frontmatter.compellingHeadline.title}
        videoURL={frontmatter.compellingHeadline.videoURL}
      />
      <TitleList
        bgTitle={frontmatter.whyJoin.title}
        list={frontmatter.whyJoin.items}
      />
      {frontmatter.imageBlock &&
      <ImageBlock
        imageContainer={frontmatter.imageBlock.imageContainer}
      />}
      <Instructions
        title={frontmatter.instructions.title}
        block1={frontmatter.instructions.block1}
        block2={frontmatter.instructions.block2}
        block3={frontmatter.instructions.block3}
      />
      <TwoButtons
        title1={frontmatter.twoButtons.title1}
        buttonLink1={frontmatter.twoButtons.buttonLink1}
        title2={frontmatter.twoButtons.title2}
        buttonLink2={frontmatter.twoButtons.buttonLink2}
      />
    </React.Fragment>
  );
};

OldTargetCustomersTemplate.propTypes = {
  frontmatter: PropTypes.object,
};

const OldTargetCustomers = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout  title={frontmatter.compellingHeadline.title || 'Info Page'}>
      <OldTargetCustomersTemplate frontmatter={frontmatter} />
    </Layout>
  );
};

OldTargetCustomers.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default OldTargetCustomers;

export const pageQuery = graphql`
  query OldTargetCustomers($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        compellingHeadline {
          title
          videoURL
        }
        whyJoin {
          title
          items
        }
        imageBlock {
          imageContainer {
            alt
            src {
              childImageSharp {
                fluid(maxWidth: 1280) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
          }
        }
        instructions {
          title
          block1 {
            title
            description
            imageContainer {
              alt
              src {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
                extension
                publicURL
              }
            }
          }
          block2 {
            title
            description
            imageContainer {
              alt
              src {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
                extension
                publicURL
              }
            }
          }
          block3 {
            title
            description
            imageContainer {
              alt
              src {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
                extension
                publicURL
              }
            }
          }
        }
        twoButtons {
          title1
          buttonLink1 {
            label
            path
          }
          title2
          buttonLink2 {
            label
            path
          }
        } 
      }
    }
  }
`;
