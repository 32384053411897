import React from 'react';
import PropTypes from 'prop-types';
import './imageBlock.scss';
import PreviewCompatibleImage from "../../Common/PreviewCompatibleImage";

const ImageBlock = ({ imageContainer }) => {
  return (
    <section className="position-relative imageBlock">
      <div className="container py-7">
        <div className="row justify-content-center">
          <div className="col-12 col-md-12 center">
            {imageContainer && imageContainer.src && (<PreviewCompatibleImage
              imageInfo={{
                image: imageContainer.src,
                alt: imageContainer.alt,
              }}
            />)}
          </div>
        </div>
      </div>
    </section>
  );
};

ImageBlock.propTypes = {
  imageContainer: PropTypes.object,
};

export default ImageBlock;
