import React from 'react';
import PropTypes from 'prop-types';
import './instructions.scss';
import PreviewCompatibleImage from "../../Common/PreviewCompatibleImage";
import SVG from "../../Common/CustomSlider/SVG";

const Instructions = ({ title, block1, block2, block3 }) => {
  return (
    <section className="position-relative instructions">
      <div className="container py-7">
        <div className="row justify-content-center">
          <div className="col col-12 text-center">
            <h1 className="h1 text-primary">
              {title}
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col col-12 d-flex flex-wrap justify-content-center">
            {block1 &&
              <section className="position-relative card-benefit px-3 py-6 py-xl-7 flex-grow-1">
                <div>
                  {block1.imageContainer && (
                    <PreviewCompatibleImage
                      imageInfo={{
                        className: "benefit-img mb-4",
                        image: block1.imageContainer.src,
                        alt: block1.imageContainer.alt,
                      }}
                    />)}
                  <h3 className="card-title text-primary mb-4">{block1.title}</h3>
                  <p className="m-0">{block1.description}</p>
                </div>
                <div className="arrow">
                  <SVG icon="right" viewBox="6 0 12 24" />
                </div>
              </section>
            }
            {block2 &&
            <section className="position-relative card-benefit px-3 py-6 py-xl-7 flex-grow-1">
              <div>
                {block2.imageContainer && (
                  <PreviewCompatibleImage
                    imageInfo={{
                      className: "benefit-img mb-4",
                      image: block2.imageContainer.src,
                      alt: block2.imageContainer.alt,
                    }}
                  />)}
                <h3 className="card-title text-primary mb-4">{block2.title}</h3>
                <p className="m-0">{block2.description}</p>
              </div>
              <div className="arrow">
                <SVG icon="right" viewBox="6 0 12 24" />
              </div>
            </section>
            }
            {block3 &&
            <section className="position-relative card-benefit px-3 py-6 py-xl-7 flex-grow-1">
              {block3.imageContainer && (
                <PreviewCompatibleImage
                  imageInfo={{
                    className: "benefit-img mb-4",
                    image: block3.imageContainer.src,
                    alt: block3.imageContainer.alt,
                  }}
                />)}
              <h3 className="card-title text-primary mb-4">{block3.title}</h3>
              <p className="m-0">{block3.description}</p>
            </section>
            }
          </div>
        </div>
      </div>
    </section>
  );
};

Instructions.propTypes = {
  title: PropTypes.string,
  block1: PropTypes.object,
  block2: PropTypes.object,
  block3: PropTypes.object,
};

export default Instructions;
