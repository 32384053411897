import React from 'react';
import PropTypes from 'prop-types';
import './titleList.scss';

const TitleList = ({ bgTitle, smTitle, list }) => {
  return (
    <section className="position-relative titleList">
      <div className="container pt-7">
        <div className="row">
          <div className="col text-center mb-5">
            <h1 className="h1 text-primary">
              {smTitle &&
              <div>
                <span className="subtitle font-weight-bold text-primary">{smTitle}</span>
                <br />
              </div>
              }
              {bgTitle}
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col col-12">
            { list &&
            <ul className="m-0 p-0">
              { list.map((item, i) => (
                  <li className="icon-guitar mb-5" key={i}>
                    {item}
                  </li>
                ))}
            </ul>
            }
          </div>
        </div>
      </div>
    </section>
  );
};

TitleList.propTypes = {
  title: PropTypes.string,
  smTitle: PropTypes.string,
  list: PropTypes.array,
};

export default TitleList;
