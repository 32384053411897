import React from 'react';
import PropTypes from 'prop-types';

const CtaButtonBlock = ({
  label,
  path,
  isCaptureEmailForm = false,
  className = '',
  openEmailFormCallback = () => {}
}) => {

  const onEmailFormButtonClick = () => {
    openEmailFormCallback(path);
  }

  return (
    <div className={`${className} col col-12 cta-button`}>
      {
        isCaptureEmailForm  && (
          <button
            type="button"
            className="mt-4 btn-tg btn-tg-sale"
            onClick={onEmailFormButtonClick}
          >
            {label}
          </button>
        )
      }
      {
        !isCaptureEmailForm && (
          <a href={path} className="mt-4 btn-tg btn-tg-sale" target="_blank" rel="noopener noreferrer">{label}</a>
        )
      }
    </div>
  );
}

CtaButtonBlock.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  isCaptureEmailForm: PropTypes.bool,
  className: PropTypes.string,
  openEmailFormCallback: PropTypes.func
};

export default CtaButtonBlock;
